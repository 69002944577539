import gql from "graphql-tag";

const POST_QUERY = gql`
  query Posts($id: ID!) {
    post(id: $id) {
      id
      title
      preview
      url
      blog {
        title
        url
        author
        posts {
          id
          title
          preview
        }
      }
    }
  }
`;

export default POST_QUERY;
