import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import PostPage from "../../components/PostPage"
import POST_QUERY from "../../queries/post/post"

const Post = () => {
  let { id } = useParams();
  return (
    <Query query={POST_QUERY} id={id}>
      {({ data: { post } }) => {
        return (
          <PostPage post={post} />
        );
      }}
    </Query>
  );
};

export default Post;
