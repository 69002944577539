import React from "react";
import { Link } from "react-router-dom";


const Nav = () => {
  
  return (
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link to="/" ariaLabel="Home">
                    <img className="h-8 w-auto sm:h-10" src="/logo.svg" alt="Logo" />
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                      <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0 md:space-x-5">
              <Link to="/search" className="font-medium text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out">Search</Link>
              <Link to="/contribute" className="font-medium text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out">Contribute</Link>
              <Link to="/about" className="font-medium text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out">About</Link>
              </div>
            </nav>
          </div>
  );
};

export default Nav;
