import React from "react";
import PostCard from "../PostPage";

const Posts = ({ posts }) => {
  return (
    <div>
      <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {posts.map((post, i) => {
          return <PostCard post={post} key={`post__${post.id}`} />;
        })}
      </ul>
    </div>
  );
};

export default Posts;
