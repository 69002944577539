import React from "react";

import { Switch, Route } from "react-router-dom";

import Nav from "../../components/Nav";
import Blogs from "../Blogs";
import Landing from "../../components/Landing";
import Contribute from "../../components/Contribute";
import About from "../../components/About";
import Search from "../../components/Search";
import Footer from "../../components/Footer";
import Posts from "../Posts";
import Post from '../Post'

function App() {
  return (
    <div className="App bg-gray-100 min-h-screen flex flex-col">
      <div className="relative pt-6 flex-grow">
        <Nav />

        <main className="mx-auto max-w-screen-xl px-4 ">
          <Switch>
            <Route path="/" component={Landing} exact />
            <Route path="/content/:id" component={Post} exact />
            <Route path="/blogs" component={Blogs} exact />
            <Route path="/search" component={Search} exact />
            <Route path="/posts" component={Posts} exact />
            <Route path="/contribute" component={Contribute} exact />
            <Route path="/about" component={About} exact />
          </Switch>
        </main>

      </div>
        <Footer />
    </div>
  );
}

export default App;
