import React from "react";
import { Link } from "react-router-dom";
import { Highlight } from 'react-instantsearch-dom';

const SearchCard = ({ post }) => {

  return (
    <li className="p-4 m-4 bg-white rounded-md">
      <Link to={"/content/" + post.id} class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
        <div class="md:flex">
          {/* <div class="md:flex-shrink-0">
            <img class="rounded-lg md:w-56" src="https://images.unsplash.com/photo-1556740738-b6a63e27c4df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=448&q=80" alt="Woman paying for a purchase" />
          </div> */}
          <div class="mt-4 md:mt-0 md:ml-6">
            <div class="tracking-wide text-lg text-indigo-600 font-bold"><Highlight hit={post} attribute="title"/></div>
            <a href={post.url} class="block mt-1 text-sm leading-tight font-semibold text-gray-900 hover:underline"><Highlight hit={post} attribute="url"/></a>
            <p class="mt-2 text-gray-600"><Highlight hit={post} attribute="fragment"/> </p>
          </div>
        </div>
      </Link>
    </li>
  );
}

export default SearchCard;