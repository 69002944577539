import React from "react";
import { Link } from "react-router-dom";

const About = () => {
    return (
        <div className="relative p-16 px-40  overflow-hidden">
            <div className="relative py-8 rounded-lg px-40 sm:px-6 lg:px-20">
                <div className="text-lg max-w-prose mx-auto mb-6">
                    <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">About</p>
                    <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Why We're Building Indie<br className="xl:hidden" />
                        <span className="text-indigo-600">Search</span>
                    </h1>
                    <div className="">
                        <p className="text-xl text-gray-900 leading-8">
                            Search results today are too easily hacked and optimized by people trying to sell you something or get clicks, and we're tired of it.
                    </p>
                        <br />
                        <p className="text-xl text-gray-900 leading-8">
                            Most actually useful information is being produced by the individual bloggers and writers. Thanks to social platforms, newsletters, and paid subscriptions, they're able to reach millions and make an honest living.
                    </p>
                        <br />
                        <p className="text-xl text-gray-900 leading-8">
                            We think <span className="font-extrabold">Indie<span className="text-indigo-600">Search</span></span> is the missing piece.
                    </p>
                        <br />
                        <p className="text-xl text-gray-900 leading-8">
                            Search and discovery of relevant, high quality, independent writers and thinkers has been limited to word of mouth and social media.
                        </p>
                        <br />
                        <p className="text-xl text-gray-900 leading-8">
                            When you search here, you'll only get indie writer results. We're constantly adding new indie writers to our engine. If you know of any great indie writers, please let us know by  
                            <Link to="/contribute" className="text-indigo-600"> adding them here!</Link>
                        </p>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default About;