import gql from "graphql-tag";

const POSTS_QUERY = gql`
  query Posts {
    posts {
      id
      title
      url
      blog {
        title
        url
        author
      }
    }
  }
`;

export default POSTS_QUERY;
