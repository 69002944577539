import React, { useState } from "react";


const Landing = ({history}) => {

    const [value, setValue] = useState('');

    const onValueChange = (e) => setValue(e.target.value);

    const handleClick = (e) => {
        e.preventDefault();
        history.push('/search?q='+value);
    }

    const onEnterPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            history.push('/search?q='+value);
        }
    }

    return (
        <div className="mt-10 text-center sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Indie
            <br className="xl:hidden" />
                <span className="text-indigo-600">Search</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Find blogs, articles and better, more relevant information <br /> outside of the world of clickbait, content marketing, and SEO.
            </p>
            <div class="py-8 px-4 overflow-hidden sm:px-6 lg:px-8 ">
                <div class="relative max-w-xl mx-auto flex">
                    <input
                        value={value}
                        onChange={onValueChange}
                        onKeyDown={onEnterPress}
                        className="p-4 rounded-md appearance-none bg-gray-300 w-full text-gray-700 placeholder-indigo-400 mr-3 leading-tight focus:outline-none"
                        placeholder="Try: stratechery facebook"
                    />
                    <button onClick={handleClick} class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none transition duration-150 ease-in-out">
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Landing;

