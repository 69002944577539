import React from "react";
import CardBlog from "../CardBlog";

const Blogs = ({ blogs }) => {
  return (
    <div>
      <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {blogs.map((blog, i) => {
          return <CardBlog blog={blog} key={`blog__${blog.id}`} />;
        })}
      </ul>
    </div>
  );
};

export default Blogs;
