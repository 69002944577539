import React from "react";
import { Link } from "react-router-dom";

const PostPage = ({ post }) => {

  console.log(post);
  return (
    <div className="py-16 bg-gray-50 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div className="relative z-10 mb-12 lg:mb-0">
        <div className="text-base max-w-prose mx-auto lg:max-w-none">
          <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">{post.blog.author}</p>
          <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{post.title}</h1>
        </div>
        
            <div className="mb-10 prose text-gray-700 mx-auto lg:max-w-none">
              {post.preview.substring(0, 500) + "..."}
            </div>
            <div className="flex text-base max-w-prose mx-auto lg:max-w-none">
              <span class="inline-flex rounded-md shadow-sm">
                <a href={post.url} class="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 hover:text-gray-200">
                  Read More
                </a>
              </span>
              {/* <div className="rounded-md shadow ml-4">
            <a href="#" className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-600 bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out">
              Learn more
            </a>
          </div> */}
            </div>
          </div>
          <div className="">
          
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
              <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap ">
                <div class="ml-4 mt-4">
                <p className="text-base leading-6 text-indigo-600 tracking-wide mb-3">About The Author</p>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    {post.blog.title}
                </h3>
                  <p class="mt-1 text-sm leading-5 text-gray-500">
                    {post.blog.author}
                </p>
                
                </div>
                <div class="ml-4 mt-4 flex-shrink-0">
                  <span class="inline-flex rounded-md shadow-sm">
                    <a href={post.blog.url} class="relative inline-flex items-center px-8 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none hover:text-gray-200  focus:border-indigo-700 active:bg-indigo-700">
                      Visit
                  </a>
                  </span>
                </div>
              </div>
            </div>
            <p class="mt-4 px-6 text-sm leading-5 text-gray-700">
                    More By {post.blog.author}
                </p>
            <ul>
             {post.blog.posts.map((child, i) => {
               if (i > 3) return ""
               return (<RelatedItem post={child} id={"child__"+child.id} />)
             })}
            </ul>
          </div>
          </div>
        </div>
      </div>
    </div >

  );
};

const RelatedItem = ({post}) => {
  return (
    <li className="border-gray-200 border-b">
                <Link to={"/content/" + post.id} className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                  <div className="px-4 py-4 flex items-center sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                          {post.title}
                        </div>
                        <div className="mt-2 flex">
                          <div className="flex items-center text-sm leading-5 text-gray-700">
                            <span>
                              {post.preview.substring(0,200) + "..."}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 flex-shrink-0">
                      <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </Link>
              </li>
  )
}

export default PostPage;