import React, {useState} from "react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectHits, connectSearchBox } from 'react-instantsearch-dom';
import SearchCard from "../SearchCard";
import queryString from "query-string";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_PUBLIC, process.env.REACT_APP_ALGOLIA_PRIVATE);

const Hits = ({ hits }) => {

    let urls = new Set();
    let filteredHits = hits.filter((post) => {
        if (!urls.has(post.url)) {
            urls.add(post.url);
            return true;
        }
        return false;
    })

    
    return (
        <>
            <p class="ml-4 tracking-wide text-md text-gray-600">Results: {hits.length} hits in {filteredHits.length} article {filteredHits.length > 1 ? "s" : "" }</p>

            <ul>
                {filteredHits.map((post, i) => {
                    post.id = post.postId;
                    return <SearchCard post={post} key={`post__${post.objectID}`} />;
                })}
            </ul>
        </>
    );
}

const CustomHits = connectHits(Hits);

const SearchBox = ({ currentRefinement, refine }) => {
    const [value, setValue] = useState(currentRefinement);

    return (
        <input
            className="p-4 rounded-md appearance-none bg-gray-300 w-full text-gray-700 placeholder-indigo-600 mr-3 leading-tight focus:outline-none"
            type="search"
            placeholder="Search here..."
            value={value}
            onChange={e => {
                setValue(e.currentTarget.value)
            }}
            onKeyPress={e => {
                if (e.key === "Enter") {
                    refine(e.currentTarget.value);
                }
            }}
        />
    );
}

const CustomSearchBox = connectSearchBox(SearchBox);

const Search = ({ location }) => {
    let query = queryString.parse(location.search);
    let q = query ? query.q : ""
    console.log(q);




    return (
        <InstantSearch searchClient={searchClient} indexName="indiesearch_1">
            <div class="w-full mx-auto">
                <div class="items-center p-4 rounded-md">
                    <div class="py-8 px-4 overflow-hidden sm:px-6 lg:px-8 ">
                        <div class="relative max-w-xl mx-auto">
                            <CustomSearchBox
                                autoFocus={true}
                                defaultRefinement={q}
                                searchAsYouType={false}
                            />
                        </div >
                    </div >
                </div>
            </div>
            <CustomHits />
        </InstantSearch>
    );
}

export default Search;
