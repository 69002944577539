import React from "react";
import { Link } from "react-router-dom";

const CardBlog = ({ blog }) => {
  
  return (
    <Link to={`/blog/${blog.id}`} >
      <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
    <div class="flex-1 flex flex-col p-8">
      <h3 class=" text-gray-900 text-sm leading-5 font-medium">{blog.title}</h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-gray-500 text-sm leading-5">{blog.author}</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">
          <span class="px-2 py-1 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">{blog.url}</span>
        </dd>
      </dl>
    </div>
    <div class="border-t border-gray-200">
      <div class="-mt-px flex">
        <div class="w-0 flex-1 flex border-r border-gray-200">
          <a href={blog.url} class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
            <span class="ml-3">Link to Post</span>
          </a>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a href={blog.url} class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
            <span class="ml-3">Blog Home</span>
          </a>
        </div>
      </div>
    </div>
    </li>
     
    </Link>
  );
};

export default CardBlog;