import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

let backend = `${process.env.REACT_APP_BACKEND_URL_PROD}/graphql`
console.log(backend);

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: backend
});
console.log(backend);
const client = new ApolloClient({
  cache,
  link
});

export default client;
