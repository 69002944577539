import React, { useState } from "react";
import { Link } from "react-router-dom";

const Contribute = () => {

    const [value, setValue] = useState('');
    const [submit, setSubmit] = useState(false);
    const onValueChange = (e) => setValue(e.target.value);

    const handleClick = (e) => {
        onSubmit(e);
        console.log(value);
        setSubmit(true);
        setValue('')

    }

    const onEnterPress = (e) => {
        if (e.key === "Enter") {
            onSubmit(e);
            setSubmit(true);
            setValue('')
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        fetch(`https://hooks.zapier.com/hooks/catch/170534/oaseacn`, {
            method: 'POST',
            body: JSON.stringify({ "link": value }),
        })
    }

    return (
        < div className="relative py-16 px-40 overflow-hidden" >

            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto mb-6">
                    <p className="text-base text-center leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Contribute</p>
                    <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Want to be on IndieSearch?</h1>
                    <p className="text-xl text-center text-gray-700 leading-8">Submit a single post or entire blog</p>
                    {submit ? (
                        <p className="text-xl text-center text-indigo-700 leading-8">Thanks for submitting!</p>
                    ) : <></>}

                </div>
                <div class="py-8 px-4 overflow-hidden sm:px-6 lg:px-8 ">
                    <div class="relative max-w-xl mx-auto flex">

                        <input
                            value={value}
                            onChange={onValueChange}
                            onKeyDown={onEnterPress}
                            className="p-4 rounded-md appearance-none bg-gray-300 w-full text-gray-700 placeholder-indigo-400 mr-3 leading-tight focus:outline-none"
                            placeholder="ex: https://stratechery.com/"
                        />
                        <button
                            onClick={handleClick}
                            class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none transition duration-150 ease-in-out">
                            Submit
                    </button>

                    </div>
                </div>
                <p className="text-sm text-center text-gray-700 leading-8">View our content policy <Link to="/about" className="text-indigo-600">here</Link></p>
            </div>
        </div >

    );
};

export default Contribute;