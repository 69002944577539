import gql from "graphql-tag";

const BLOGS_QUERY = gql`
  query Blogs {
    blogs {
      id
      title
      author
      url
    }
  }
`;

export default BLOGS_QUERY;