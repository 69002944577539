import React from "react";
import Blogs from "../../components/Blogs";
import Query from "../../components/Query";
import BLOGS_QUERY from "../../queries/blog/blogs";

const Home = () => {
  return (
    <div>
      <div className="uk-section">
        <div className="uk-container uk-container-large">
          <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide mb-4">Results</h2>
          <Query query={BLOGS_QUERY}>
            {({ data: { blogs } }) => {
              return <Blogs blogs={blogs} />;
            }}
          </Query>
        </div>
      </div>
    </div>
  );
};

export default Home;
